import style from './main.module.scss'

const Main = () => {
	return (
		<div className={style.main}>
			<div className={style.main_titles}>
				<div className={style.title}>
					<h1>
						Агентство кадровой <br /> безопасности <span>«Защита»</span>
					</h1>
				</div>
				<div className={style.bottom_title}>
					<a href='#contact'>
						<button>
							<h2>Связаться с нами</h2>
						</button>
					</a>
					<div>
						<h1>«Воруют там, где нет контроля»</h1>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Main
