import style from './services.module.scss'

import img from '../../images/img1.png'
import img2 from '../../images/img2.png'
import img3 from '../../images/img3.png'
import img4 from '../../images/img4.png'
import img5 from '../../images/img5.png'
import img6 from '../../images/img6.png'
import img7 from '../../images/img7.png'
import img8 from '../../images/img8.png'
import img9 from '../../images/img9.png'
import img10 from '../../images/img10.png'
import img11 from '../../images/img11.png'

const Services = () => {
	return (
		<div className={style.services} id='services'>
			<h1>Наши услуги:</h1>
			<div className={style.lisservices}>
				<div className={style.service}>
					<div>
						<h3>01</h3>
						<h2>
							Проверка кандидатов при приёме на работу и текущих сотрудников по информационным базам.
						</h2>
					</div>

					<img src={img} alt='Проверка кандидатов' />
				</div>
				<div className={style.service}>
					<div>
						<h3>02</h3> <h2>Проверка на судимость.</h2>
					</div>

					<img src={img2} alt='Проверка кандидатов' />
				</div>
				<div className={style.service}>
					<div>
						<h3>03</h3> <h2>Проверка кандидатов при приёме на работу с использованием полиграфа.</h2>
					</div>

					<img src={img3} alt='Проверка кандидатов' />
				</div>
				<div className={style.service}>
					<div>
						<h3>04</h3>{' '}
						<h2>Проверка на благонадёжность действующего персонала с использованием полиграфа.</h2>
					</div>

					<img src={img4} alt='Проверка кандидатов' />
				</div>
				<div className={style.service}>
					<div>
						<h3>05</h3> <h2>Проверка потенциальных контрагентов.</h2>
					</div>

					<img src={img5} alt='Проверка кандидатов' />
				</div>
				<div className={style.service}>
					<div>
						<h3>06</h3>
						<h2>
							Проведение аудита, направленного на выявление потенциальных точек возникновения хищений
							и (или) махинаций.
						</h2>
					</div>

					<img src={img6} alt='Проверка кандидатов' />
				</div>
				<div className={style.service}>
					<div>
						<h3>07</h3>
						<h2>Консультационные услуги по созданию или модернизации системы внутреннего контроля.</h2>
					</div>

					<img src={img7} alt='Проверка кандидатов' />
				</div>
				<div className={style.service}>
					<div>
						<h3>08</h3> <h2>Проведение служебных расследований.</h2>
					</div>

					<img src={img8} alt='Проверка кандидатов' />
				</div>
				<div className={style.service}>
					<div>
						<h3>09</h3> <h2>Проверка помещений и каналов на предмет утечки информации.</h2>
					</div>

					<img src={img9} alt='Проверка кандидатов' />
				</div>
				<div className={style.service}>
					<div>
						<h3>10</h3>
						<h2>
							Оказание консультационных услуг и приведение в соответствие с нормативными актами
							документов и помещений для получения лицензии на работу со сведениями, составляющими
							государственную тайну.
						</h2>
					</div>

					<img src={img10} alt='Проверка кандидатов' />
				</div>
				<div className={style.service}>
					<div>
						<h3>11</h3>
						<h2>
							Помощь ИП и юридическим лицам в оформлении и введения в работу положения о коммерческой
							тайне.
						</h2>
					</div>

					<img src={img11} alt='Проверка кандидатов' />
				</div>
			</div>

			<span>
				Услуги выездной проверки в области и регионах.
				<br />
				Работаем как с физическими так и с юридическим лицами.
			</span>
		</div>
	)
}

export default Services
