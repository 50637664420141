import Footer from '../Components/Footer/Footer'
import Header from '../Components/Header/Header'

const Politics = () => {
	return (
		<div>
			<header>
				<Header />
			</header>
			<main>
				<div className='politics'>
					<h1>Политика конфиденциальности</h1>
					<h2>Политика в отношении обработки персональных данных</h2>

					<h3>1. Общие положения</h3>
					<p>
						Настоящая политика обработки персональных данных составлена в соответствии с требованиями
						Федерального закона от 27.07.2006. №152-ФЗ «О персональных данных» и определяет порядок
						обработки персональных данных и меры по обеспечению безопасности персональных данных.
					</p>
					<ol>
						<li>
							Оператор ставит своей важнейшей целью и условием осуществления своей деятельности
							соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в
							том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.
						</li>
						<li>
							Настоящая политика Оператора в отношении обработки персональных данных (далее –
							Политика) применяется ко всей информации, которую Оператор может получить о посетителях
							веб-сайта zashita774.ru.
						</li>
					</ol>

					<h3>2. Основные понятия, используемые в Политике</h3>
					<ol>
						<li>
							Автоматизированная обработка персональных данных – обработка персональных данных с
							помощью средств вычислительной техники;
						</li>
						<li>
							Блокирование персональных данных – временное прекращение обработки персональных данных
							(за исключением случаев, если обработка необходима для уточнения персональных данных);
						</li>
						<li>
							Веб-сайт – совокупность графических и информационных материалов, а также программ для
							ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу
							zashita774.ru;
						</li>
						<li>
							Информационная система персональных данных — совокупность содержащихся в базах данных
							персональных данных, и обеспечивающих их обработку информационных технологий и
							технических средств;
						</li>
						<li>
							Обезличивание персональных данных — действия, в результате которых невозможно определить
							без использования дополнительной информации принадлежность персональных данных
							конкретному Пользователю или иному субъекту персональных данных;
						</li>
						<li>
							Обработка персональных данных – любое действие (операция) или совокупность действий
							(операций), совершаемых с использованием средств автоматизации или без использования
							таких средств с персональными данными, включая сбор, запись, систематизацию, накопление,
							хранение, уточнение (обновление, изменение), извлечение, использование, передачу
							(распространение, предоставление, доступ), обезличивание, блокирование, удаление,
							уничтожение персональных данных;
						</li>
						<li>
							Оператор – государственный орган, муниципальный орган, юридическое или физическое лицо,
							самостоятельно или совместно с другими лицами организующие и (или) осуществляющие
							обработку персональных данных, а также определяющие цели обработки персональных данных,
							состав персональных данных, подлежащих обработке, действия (операции), совершаемые с
							персональными данными;
						</li>
						<li>
							Персональные данные – любая информация, относящаяся прямо или косвенно к определенному
							или определяемому Пользователю веб-сайта zashita774.ru;
						</li>
						<li>Пользователь – любой посетитель веб-сайта zashita774.ru;</li>
						<li>
							Предоставление персональных данных – действия, направленные на раскрытие персональных
							данных определенному лицу или определенному кругу лиц;
						</li>
						<li>
							Распространение персональных данных – любые действия, направленные на раскрытие
							персональных данных неопределенному кругу лиц (передача персональных данных) или на
							ознакомление с персональными данными неограниченного круга лиц, в том числе
							обнародование персональных данных в средствах массовой информации, размещение в
							информационно-телекоммуникационных сетях или предоставление доступа к персональным
							данным каким-либо иным способом;
						</li>
						<li>
							Трансграничная передача персональных данных – передача персональных данных на территорию
							иностранного государства органу власти иностранного государства, иностранному
							физическому или иностранному юридическому лицу;
						</li>
						<li>
							Уничтожение персональных данных – любые действия, в результате которых персональные
							данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания
							персональных данных в информационной системе персональных данных и (или) результате
							которых уничтожаются материальные носители персональных данных.
						</li>
					</ol>

					<h3>3. Оператор может обрабатывать следующие персональные данные Пользователя</h3>
					<ol>
						<li>Фамилия, имя, отчество;</li>
						<li>Электронный адрес;</li>
						<li>Номера телефонов;</li>
						<li>Год, месяц, дата и место рождения;</li>
						<li>Реквизиты документа, удостоверяющего личность;</li>
						<li>
							Адрес фактического места проживания и регистрации по месту жительства и (или) по месту
							пребывания;
						</li>
						<li>Сведения о задолженности;</li>
						<li>Действительность паспорта;</li>
						<li>Нахождение в розыске по базе МВД по всем регионам РФ;</li>
						<li>Наличие исполнительных производств;</li>
						<li>Регистрация в качестве ИП (индивидуального предпринимателя);</li>
						<li>Работа в качестве руководителя ООО, ЗАО, АО;</li>
						<li>Наличие доли в уставном капитале организаций;</li>
						<li>ИНН физического лица;</li>
						<li>Должность;</li>
						<li>Национальность;</li>
						<li>Числовая оценка качества кредитной истории;</li>
						<li>Номер и дата выдачи водительского удостоверения;</li>
						<li>Штрафы ГИБДД по водительскому удостоверению;</li>
						<li>Нахождение в списках террористов и экстремистов;</li>
						<li>Прочие сведения, которые могут идентифицировать человека.;</li>
						<li>
							Также на сайте происходит сбор и обработка обезличенных данных о посетителях (в т.ч.
							файлов «cookie») с помощью сервисов интернет-статистики (Яндекс Метрика и Гугл Аналитика
							и других);
						</li>
						<li>
							Вышеперечисленные данные далее по тексту Политики объединены общим понятием Персональные
							данные.
						</li>
					</ol>

					<h3>4. Цели обработки персональных данных</h3>
					<ol>
						<li>
							Целью обработки персональных данных Пользователя является информирование Пользователя
							посредством отправки электронных писем.
						</li>
						<li>
							Также Оператор имеет право направлять Пользователю уведомления о новых продуктах и
							услугах, специальных предложениях и различных событиях. Пользователь всегда может
							отказаться от получения информационных сообщений, направив Оператору письмо на адрес
							электронной почты zashita774@yandex.ru с пометкой «Отказ от уведомлений о новых
							продуктах и услугах и специальных предложениях».
						</li>
						<li>
							Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики,
							служат для сбора информации о действиях Пользователей на сайте, улучшения качества сайта
							и его содержания.
						</li>
					</ol>

					<h3>5. Правовые основания обработки персональных данных</h3>
					<ol>
						<li>
							Оператор обрабатывает персональные данные Пользователя только в случае их заполнения
							и/или отправки Пользователем самостоятельно через специальные формы, расположенные на
							сайте zashita774.ru. Заполняя соответствующие формы и/или отправляя свои персональные
							данные Оператору, Пользователь выражает свое согласие с данной Политикой.
						</li>
						<li>
							Оператор обрабатывает обезличенные данные о Пользователе в случае, если это разрешено в
							настройках браузера Пользователя (включено сохранение файлов «cookie» и использование
							технологии JavaScript).
						</li>
					</ol>

					<h3>6. Порядок сбора, хранения, передачи и других видов обработки персональных данных</h3>
					<ol>
						<li>
							Оператор обеспечивает сохранность персональных данных и принимает все возможные меры,
							исключающие доступ к персональным данным неуполномоченных лиц.
						</li>
						<li>
							Персональные данные Пользователя никогда, ни при каких условиях не будут переданы
							третьим лицам, за исключением случаев, связанных с исполнением действующего
							законодательства.
						</li>
						<li>
							В случае выявления неточностей в персональных данных, Пользователь может актуализировать
							их самостоятельно, путем направления Оператору уведомления на адрес электронной почты
							Оператора zashita774@yandex.ru с пометкой «Актуализация персональных данных».
						</li>
						<li>
							Срок обработки персональных данных является неограниченным. Пользователь может в любой
							момент отозвать свое согласие на обработку персональных данных, направив Оператору
							уведомление посредством электронной почты на электронный адрес Оператора
							zashita774@yandex.ru с пометкой «Отзыв согласия на обработку персональных данных».
						</li>
					</ol>

					<h3>7. Трансграничная передача персональных данных</h3>
					<ol>
						<li>
							Оператор до начала осуществления трансграничной передачи персональных данных обязан
							убедиться в том, что иностранным государством, на территорию которого предполагается
							осуществлять передачу персональных данных, обеспечивается надежная защита прав субъектов
							персональных данных.
						</li>
						<li>
							Трансграничная передача персональных данных на территории иностранных государств, не
							отвечающих вышеуказанным требованиям, может осуществляться только в случае наличия
							согласия в письменной форме субъекта персональных данных на трансграничную передачу его
							персональных данных и/или исполнения договора, стороной которого является субъект
							персональных данных.
						</li>
					</ol>

					<h3>8. Заключительные положения</h3>
					<ol>
						<li>
							Пользователь может получить любые разъяснения по интересующим вопросам, касающимся
							обработки его персональных данных, обратившись к Оператору с помощью электронной почты
							zashita774@yandex.ru.
						</li>
						<li>
							В данном документе будут отражены любые изменения политики обработки персональных данных
							Оператором. Политика действует бессрочно до замены ее новой версией.
						</li>
						<li>
							Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу
							zashita774.ru.
						</li>
					</ol>
				</div>
			</main>
			<footer>
				<Footer />
			</footer>
		</div>
	)
}

export default Politics
