import React, { useState } from 'react'
import axios from 'axios'
import style from './blockCheckPerson.module.scss'
import { Link } from 'react-router-dom'
import InputMask from 'react-input-mask'

const BlockCheckPerson = () => {
	const [formData, setFormData] = useState({
		full_name: '',
		email: '',
		amount: 500,
		family_name: '',
		first_name: '',
		middle_name: '',
		previous_family_names: '',
		birth_date: '',
		search_region: ''
	})

	const [errors, setErrors] = useState({
		family_name: false,
		first_name: false,
		middle_name: false,
		previous_family_names: false,
		search_region: false,
		birth_date: false
	})

	const nameRegex = /^[А-Яа-яЁё]+$/

	const handleChange = e => {
		const { name, value } = e.target

		if (name === 'family_name' || name === 'first_name' || name === 'middle_name') {
			if (!nameRegex.test(value)) {
				setErrors({
					...errors,
					[name]: true
				})
			} else {
				setErrors({
					...errors,
					[name]: false
				})
			}
		}

		setFormData({
			...formData,
			[name]: value
		})
	}

	const handleBirthDateChange = e => {
		setFormData({
			...formData,
			birth_date: e.target.value
		})
	}

	const handleSubmit = e => {
		e.preventDefault()

		const updatedFormData = {
			...formData,
			full_name: `${formData.email} ${formData.amount}`
		}

		axios.post('https://zashita774.ru/api/create-payment/', updatedFormData, {
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			}
		})
			.then(response => {
				const confirmationUrl = response.data.confirmation_url
				if (confirmationUrl) {
					window.location.href = confirmationUrl
				}
			})
			.catch(error => {
				console.error('There was an error!', error)
			})
	}

	return (
		<div className={style.blockCheckPerson}>
			<div className={style.block_left}>
				<h1>Проверка физических лиц</h1>
				<form onSubmit={handleSubmit}>
					<input
						className={errors.family_name ? style.inputError : ''}
						placeholder='Фамилия*'
						type='text'
						name='family_name'
						value={formData.family_name}
						onChange={handleChange}
						required
					/>
					<input
						className={errors.first_name ? style.inputError : ''}
						placeholder='Имя*'
						type='text'
						name='first_name'
						value={formData.first_name}
						onChange={handleChange}
						required
					/>
					<input
						className={errors.middle_name ? style.inputError : ''}
						placeholder='Отчество'
						type='text'
						name='middle_name'
						value={formData.middle_name}
						onChange={handleChange}
					/>
					<input
						className={errors.previous_family_names ? style.inputError : ''}
						placeholder='Прежние фамилии'
						type='text'
						name='previous_family_names'
						value={formData.previous_family_names}
						onChange={handleChange}
					/>
					<InputMask
						mask='99.99.9999'
						maskChar=''
						placeholder='Дата рождения*'
						value={formData.birth_date}
						onChange={handleBirthDateChange}
					>
						{inputProps => (
							<input
								{...inputProps}
								type='text'
								className={errors.birth_date ? style.inputError : ''}
							/>
						)}
					</InputMask>
					<input
						className={errors.search_region ? style.inputError : ''}
						placeholder='Регион поиска*'
						type='text'
						name='search_region'
						value={formData.search_region}
						onChange={handleChange}
						required
					/>
					<input
						placeholder='Ваша почта*'
						type='email'
						name='email'
						value={formData.email}
						onChange={handleChange}
						required
					/>
					<button type='submit' disabled={Object.values(errors).some(error => error)}>
						Проверить за 500 ₽
					</button>
					<div className={style.checkbox}>
						<input type='checkbox' required />
						<Link to='/politics'>
							<span>
								Я даю своё согласие на обработку персональных данных и соглашаюсь с условиями
								Политики конфиденциальности
							</span>
						</Link>
					</div>
					<div className={style.checkbox}>
						<input id='c1' type='checkbox' required />
						<Link to='/agreement'>
							<span>Я согласен с условиями Пользовательского соглашения</span>
						</Link>
					</div>
				</form>
			</div>
			<div className={style.block_right}>
				<h1>Из полного отчёта вы узнаете:</h1>
				<ul>
					<li>Наличие судимостей и судебных дел</li>
					<li>Нахождение в розыске МВД, Интерпол и ФСИН </li>
					<li>Наличие спецучетов </li>
					<li>Нахождение в списке террористов и экстремистов </li>
					<li>Нахождение в реестре иностранных агентов </li>
					<li>Наличие административных правонарушений </li>
					<li>Сведения о долгах у ФССП </li>
					<li>Банкротство</li>
					<li>Сведения о государственной регистрации юрлиц и ИП</li>
				</ul>
				<h2>Соответствует ФЗ № 152 «О персональных данных».</h2>
			</div>
		</div>
	)
}

export default BlockCheckPerson
