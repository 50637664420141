import style from './pusrchase.module.scss'

const Purchase = () => {
	return (
		<div className={style.purchase} id='purchase'>
			<h1>Акции:</h1>
			<div className={style.pricelist}>
				<div className={style.price}>
					<h2>
						Проверка по информационным
						<br /> базам физического лица.
					</h2>
					<p>500 ₽</p>
				</div>
				<div className={style.price}>
					<h2>
						Проверка по информационным
						<br /> базам юридического лица.
					</h2>
					<p>1000 ₽</p>
				</div>
				<div className={style.price}>
					<h2>
						Проведение исследования
						<br /> с использованием полиграфа.
					</h2>
					<p>5000 ₽</p>
				</div>
			</div>
		</div>
	)
}

export default Purchase
