import Footer from '../Components/Footer/Footer'
import Header from '../Components/Header/Header'

const Agreement = () => {
	return (
		<div>
			<header>
				<Header />
			</header>
			<main>
				<div className='politics'>
					<h1>Публичный договор</h1>
					<p>
						Настоящее Соглашение регламентирует условия использования онлайн-сервиса, расположенного по
						адресу{' '}
						<a href='http://zashita774.ru' target='_blank'>
							zashita774.ru
						</a>{' '}
						(далее – Сервис), заключено между любым лицом (далее - Пользователь), использующим Сервис,
						который предназначен для получения информации об физических лицах (далее – ФЛ),
						зарегистрированных на территории Российской Федерации граждан, юридических лицах (далее – ЮЛ),
						а также устанавливает условия использования Сервиса.
					</p>
					<p>
						Настоящее Соглашение является публичной офертой в соответствии со ст. 435 Гражданского Кодекса
						РФ. Действия Пользователя по отправке запроса на получение информации об ФЛ и ЮЛ с помощью
						специальной формы на Сервисе считаются акцептом оферты согласно ст. 438 Гражданского Кодекса
						РФ и означают безоговорочное принятие Пользователем всех условий данного Соглашения без
						каких-либо изъятий или ограничений на условиях присоединения (акцептом оферты).
					</p>

					<h2>1. Определения</h2>
					<p>
						1.1. Сервис – веб-сайт{' '}
						<a href='http://zashita774.ru' target='_blank'>
							zashita774.ru
						</a>
						, предназначенный для сбора и систематизации информации.
					</p>
					<p>
						1.2. Пользователь – любое лицо, посетившее веб-сайт{' '}
						<a href='http://zashita774.ru' target='_blank'>
							zashita774.ru
						</a>
						.
					</p>
					<p>
						1.3. Неперсонифицированное электронное средство платежа – электронное средство платежа,
						предоставленное физическому лицу без проведения идентификации данного лица в соответствии с
						Федеральным законом от 7 августа 2001 года N 115-ФЗ «О противодействии легализации (отмыванию)
						доходов, полученных преступным путем, и финансированию терроризма».
					</p>
					<p>
						1.4. Материалы Сервиса – все текстовые, графические и видео материалы, размещенные на Сервисе,
						а также оформление и расположение указанных материалов (дизайн).
					</p>
					<p>1.5. Услуга – предоставление Пользователю какой-либо информации об ФЛ, ЮЛ.</p>
					<p>
						1.6. Администратор – сотрудник, курирующий получение конечным Пользователем запрашиваемой
						информации, обновляет информацию на Сервисе.
					</p>
					<p>
						1.7. Идентификационные сведения – данные, необходимые для осуществления проверки ФЛ, такие как
						ФИО, дата рождения, серия, номер, дата выдачи паспорта и водительского удостоверения.
					</p>

					<h2>2. Предмет Соглашения</h2>
					<p>
						2.1. Предметом настоящего Соглашения является оказание Администрацией Сервиса услуги
						Пользователю по предоставлению информации об ФЛ, ЮЛ в соответствии с определенными
						Пользователем Идентификационными сведениями.
					</p>
					<p>
						2.2. Пользователь самостоятельно несет ответственность перед третьими лицами за свои действия,
						связанные с использованием Сервиса, в том числе, если такие действия приведут к нарушению прав
						и законных интересов третьих лиц, а также за соблюдение законодательства при использовании
						Сервиса.
					</p>

					<h2>3. Порядок оказания услуг и порядок оплаты</h2>
					<p>
						3.1. Для получения информации об ФЛ Пользователь вводит Идентификационные сведения в форму на
						главной странице Сервиса и отправляет запрос путем нажатия кнопки «Проверить».
					</p>
					<p>3.2. Услуга получения отчета на Сервисе является платной.</p>
					<p>
						3.3. Стоимость Услуги получения информации на Сервисе составляет от 500 до 1000 рублей (в
						зависимости от выбранного вида услуги).
					</p>
					<p>
						3.4. Приём оплаты осуществляется при помощи партнеров Сервиса: компаний ЮKassa, WalletOne,
						Platron.
					</p>
					<p>
						3.5. По окончании оплаты полная версия отчета высылается на указанный Пользователем
						электронный адрес в течение 48 часов с момента оплаты.
					</p>

					<h2>4. Конфиденциальность и персональные данные</h2>
					<p>
						4.1. Сервис не содержит и не аккумулирует информацию, содержащую Персональные данные
						Пользователей или других лиц.
					</p>
					<p>
						4.2. Вся информация в рамках оказания Услуги Сервисом является общедоступной или
						предоставляется партнерами и пользователями Сервиса на добровольной основе.
					</p>
					<p>
						4.3. При оплате Услуги посредством банковской карты ее реквизиты, а также фамилия, имя и
						отчество держателя банковской карты вводятся Пользователем в онлайн-форму, предоставленную
						Сервису партнерами – платежными системами ЮKassa, WalletOne или Platron. В этом случае данные
						обрабатываются в соответствии с регламентами, установленными платежными системами и не
						сохраняются Сервисом.
					</p>

					<h2>5. Авторские права</h2>
					<p>
						5.1. Исключительные права на Материалы Сервиса принадлежат Администрации либо их
						правообладателям.
					</p>
					<p>
						5.2. Копирование Материалов Сервиса без письменного разрешения Администрации или
						правообладателей влечет ответственность в соответствии с законодательством Российской
						Федерации.
					</p>
					<p>
						5.3. Не допускается получение Пользователем либо третьими лицами или передача Пользователем
						третьим лицам информации об ФЛ, ЮЛ, полученной с помощью Сервиса с использованием
						автоматических программных и технических средств (парсинг), в том числе с целью предоставления
						такой информации на каких-либо иных веб-сайтах помимо Сервиса, а также в каком-либо ином
						программном обеспечении, включая мобильные приложения, кроме случаев использования
						официального виджета Сервиса.
					</p>
					<p>
						5.4. Использование Пользователем элементов содержания Сервиса, а также любого контента для
						личного некоммерческого использования, допускается при условии сохранения всех знаков охраны
						авторского права, смежных прав, товарных знаков, других уведомлений об авторстве, сохранения
						имени (или псевдонима) автора/наименования правообладателя в неизменном виде, сохранении
						соответствующего объекта в неизменном виде. Исключение составляют случаи, прямо
						предусмотренные законодательством РФ или настоящим Соглашениями.
					</p>
					<p>5.5. Пользователь заявляет и гарантирует, что он:</p>
					<ul>
						<li>
							a) автор любой загружаемой на Сервис (отправляемой по электронной почте) фотографии или
							видео фрагмента, а если он не является автором, то пользователь гарантирует наличие у
							него действующего разрешения автора или правообладателя на размещение материалов по всем
							условиям, предусмотренным для Пользователя-автора, зарегистрированного на Сервисе;
						</li>
						<li>
							b) до момента размещения на Сервисе любого объекта интеллектуальной собственности,
							защищённого авторскими и иными правами, получил от их автора или правообладателя все
							необходимые и безотзывные разрешения на публикацию и распространение этого объекта, по
							тем условиям, которые действуют на Сайте;
						</li>
						<li>
							c) до момента размещения на Сервисе фотографии, на которой лицо человека не
							заретушировано, получил от этого человека или его полномочного представителя требуемые
							законодательством безотзывные разрешения на публикацию и распространение его
							изображения, по тем условиям, которые действуют на Сервисе;
						</li>
						<li>
							d) сам как автор, осуществил все необходимые правки фотографии в соответствии с
							требованиями Правил, а если пользователь не автор, то он гарантирует, что автор
							фотографии сам и в его присутствии лично осуществил такие правки;
						</li>
						<li>
							e) в момент размещения им на Сервисе фотографии, иного объекта интеллектуальной
							собственности, передал Сервису исключительное право на эту фотографию, на иной объект
							интеллектуальной собственности, и правомочно разрешает Сервису использовать их без
							ограничений и бесплатно по своему усмотрению;
						</li>
						<li>
							f) согласен с тем, что любой из объектов интеллектуальной собственности, добавленных им
							на Сервис, репродукция этого объекта, могут свободно, безвозмездно использоваться
							Администрацией, другими пользователями по своему усмотрению;
						</li>
						<li>
							g) воздержится от добавления фотографии на Сервис, перестанет пользоваться Сервисом,
							если он не согласен с тем режимом публикации и размещения фотографий на нём, которые
							установлены Правилами и Администрацией Сервиса, либо если такое размещение в момент
							добавления или впоследствии может нарушить его права, права автора, правообладателя или
							права изображённого на фотографии лица;
						</li>
						<li>
							h) возместит Сервису, как издателю, убытки, связанные с удалением размещённых им на
							Сервисе объектов интеллектуальной собственности, в порядке, определяемом Сервисом;
						</li>
						<li>
							i) возместит Сервису любые убытки, вызванные незаконным или неправомерным размещением
							пользователем объектов интеллектуальной собственности на Сервисе;
						</li>
						<li>
							j) самостоятельно проверил каждый объект интеллектуальной собственности, переданный им
							Сервису, на предмет его авторства, соответствия законодательству, нормам этики и морали,
							понимая, что Администрация не имеет технической возможности осуществить сплошную и
							непрерывную проверку подобных объектов всех пользователей;
						</li>
						<li>
							k) предоставил запрос, а Администрация своевременно удалила все фотографии и информацию,
							ранее числящихся в его профиле, размещение которых на Сервисе нарушает авторские или
							исключительные права, нарушает права лиц, изображённых на фотографии, нарушает или не
							соответствует иным требованиям законодательства;
						</li>
						<li>
							l) берёт на себя полную ответственность за нарушение законодательства, норм этики и
							морали при размещении или получении им любых материалов с помощью технических средств
							Сервиса, не привлекает Администрацию или собственников Сервиса в качестве лиц, которые,
							по его мнению, автоматически обеспечивали такую техническую возможность (помощь) в
							размещение такой информации.
						</li>
					</ul>

					<h2>6. Гарантии и ограничение ответственности</h2>
					<p>
						6.1. Администрация не гарантирует отсутствия технических перерывов в работе Сервиса. Перерывы
						в предоставлении услуг обуславливаются действием или бездействием третьих лиц и/или
						неработоспособностью информационных каналов, находящихся за пределами собственных ресурсов
						Администрации, а также необходимым профилактическим ремонтом и обслуживанием оборудования
						Администрации, в том числе по аварийным обстоятельствам.
					</p>
					<p>
						6.2. Администрация не несет ответственности перед Пользователем за задержки, перебои в работе
						и невозможность полноценного использования собственных ресурсов Администрации, происходящие
						прямо или косвенно по причине действия или бездействия третьих лиц и/или неработоспособностью
						информационных каналов, находящихся за пределами собственных ресурсов Администрации.
					</p>
					<p>
						6.3. Администрация не несет ответственности за неполученную прибыль и упущенную выгоду, а
						также за любые косвенные убытки, понесенные Пользователем в период использования или не
						использования Сервиса.
					</p>
					<p>
						6.4. Администрация не несет ответственности за качество, безошибочность и наличие вредоносных
						компонентов в используемом Пользователем программном обеспечении, если таковое не разработано
						Администрацией.
					</p>
					<p>
						6.5. Администрация Сервиса не является лицом, уполномоченным на составление и хранение
						исчерпывающей информационной базы об ФЛ, ЮЛ, не несет ответственности за достоверность,
						100-процентную полноту и актуальность получаемых сведений.
					</p>
					<p>
						6.6. Информация и материалы, размещенные на этом сайте, включая тексты документов, графические
						изображения и прочее, предоставляются в той форме, в какой они имеются у Администрации
						Сервиса. Сервис не гарантирует точности, адекватности или полноты размещенной информации и
						материалов.
					</p>
					<p>6.7. Пользователь соглашается и гарантирует, что он:</p>
					<ul>
						<li>
							a) автор любой загружаемой на Сервис (отправляемой по электронной почте) фотографии или
							видео фрагмента, а если он не является автором, то пользователь гарантирует наличие у
							него действующего разрешения автора или правообладателя на размещение материалов по всем
							условиям, предусмотренным для Пользователя-автора, зарегистрированного на Сервисе;
						</li>
						<li>
							b) до момента размещения на Сервисе любого объекта интеллектуальной собственности,
							защищённого авторскими и иными правами, получил от их автора или правообладателя все
							необходимые и безотзывные разрешения на публикацию и распространение этого объекта, по
							тем условиям, которые действуют на Сайте;
						</li>
						<li>
							c) до момента размещения на Сервисе фотографии, на которой лицо человека не
							заретушировано, получил от этого человека или его полномочного представителя требуемые
							законодательством безотзывные разрешения на публикацию и распространение его
							изображения, по тем условиям, которые действуют на Сервисе;
						</li>
						<li>
							d) сам как автор, осуществил все необходимые правки фотографии в соответствии с
							требованиями Правил, а если пользователь не автор, то он гарантирует, что автор
							фотографии сам и в его присутствии лично осуществил такие правки;
						</li>
						<li>
							e) в момент размещения им на Сервисе фотографии, иного объекта интеллектуальной
							собственности, передал Сервису исключительное право на эту фотографию, на иной объект
							интеллектуальной собственности, и правомочно разрешает Сервису использовать их без
							ограничений и бесплатно по своему усмотрению;
						</li>
						<li>
							f) согласен с тем, что любой из объектов интеллектуальной собственности, добавленных им
							на Сервис, репродукция этого объекта, могут свободно, безвозмездно использоваться
							Администрацией, другими пользователями по своему усмотрению;
						</li>
						<li>
							g) воздержится от добавления фотографии на Сервис, перестанет пользоваться Сервисом,
							если он не согласен с тем режимом публикации и размещения фотографий на нём, которые
							установлены Правилами и Администрацией Сервиса, либо если такое размещение в момент
							добавления или впоследствии может нарушить его права, права автора, правообладателя или
							права изображённого на фотографии лица;
						</li>
						<li>
							h) возместит Сервису, как издателю, убытки, связанные с удалением размещённых им на
							Сервисе объектов интеллектуальной собственности, в порядке, определяемом Сервисом;
						</li>
						<li>
							i) возместит Сервису любые убытки, вызванные незаконным или неправомерным размещением
							пользователем объектов интеллектуальной собственности на Сервисе;
						</li>
						<li>
							j) самостоятельно проверил каждый объект интеллектуальной собственности, переданный им
							Сервису, на предмет его авторства, соответствия законодательству, нормам этики и морали,
							понимая, что Администрация не имеет технической возможности осуществить сплошную и
							непрерывную проверку подобных объектов всех пользователей;
						</li>
						<li>
							k) предоставил запрос, а Администрация своевременно удалила все фотографии и информацию,
							ранее числящихся в его профиле, размещение которых на Сервисе нарушает авторские или
							исключительные права, нарушает права лиц, изображённых на фотографии, нарушает или не
							соответствует иным требованиям законодательства;
						</li>
						<li>
							l) берёт на себя полную ответственность за нарушение законодательства, норм этики и
							морали при размещении или получении им любых материалов с помощью технических средств
							Сервиса, не привлекает Администрацию или собственников Сервиса в качестве лиц, которые,
							по его мнению, автоматически обеспечивали такую техническую возможность (помощь) в
							размещении такой информации.
						</li>
					</ul>
					<p>
						6.8. За невыполнение или ненадлежащее выполнение обязательств по настоящему Соглашению Стороны
						несут ответственность в соответствии с действующим законодательством, если иное не установлено
						Соглашением.
					</p>
					<p>
						6.9. Стороны освобождаются от ответственности за неисполнение (ненадлежащее исполнение)
						Соглашения, если такое неисполнение (ненадлежащее исполнение) явилось следствием действий
						обстоятельств непреодолимой силы, наступление которых Стороны не могли предвидеть и
						предотвратить. Сторона, для которой надлежащее исполнение обязательства стало невозможным
						ввиду действия обстоятельств непреодолимой силы, обязана незамедлительно уведомить об этом
						другую Сторону. Стороны вправе ссылаться на действия обстоятельств непреодолимой силы лишь при
						условии, что они сделали все возможное в целях предотвращения и/или минимизации негативных
						последствий действия указанных обстоятельств.
					</p>
					<p>
						6.10. В случае привлечения Правообладателя к ответственности или наложения на него взыскания в
						связи с допущенными Пользователем нарушениями прав третьих лиц, а равно установленных
						законодательством запретов или ограничений, Пользователь обязан в полном объеме возместить
						убытки Правообладателя.
					</p>
					<p>
						6.11. В случае нарушения Пользователем условий и ограничений настоящего Соглашения, он
						является нарушителем авторского права. За нарушение авторских прав Пользователь несет
						ответственность в соответствии с законодательством Российской Федерации.
					</p>
					<p>
						6.12. Администрация Сервиса не принимает участия в возможных разногласиях и спорах (в т.ч. и
						судебных) по итогам использования информации, полученной с помощью Сервиса.
					</p>

					<h2>7. Исключительные права на контент</h2>
					<p>
						7.1. Все объекты, размещенные на Сервисе, в том числе элементы дизайна, текст, графические
						изображения, иллюстрации, видео, скрипты, программы, музыка, звуки и другие объекты и их
						подборки (далее — Контент), являются объектами авторских прав Правообладателя, все права на
						эти объекты защищены.
					</p>
					<p>
						7.2. Кроме случаев, установленных настоящим Соглашением, а также действующим законодательством
						Российской Федерации, Контент не может быть скопирован (воспроизведен), переработан,
						распространен, опубликован, скачан, передан, продан или иным способом использован целиком или
						по частям без предварительного разрешения Правообладателя, кроме случаев, когда
						Правообладатель явным образом выразил свое согласие на свободное использование Контента любым
						лицом.
					</p>
					<p>
						7.3. Использование Пользователем Контента, доступ к которому получен исключительно для личного
						некоммерческого использования, допускается при условии сохранения всех знаков авторства или
						других уведомлений об авторстве, сохранения имени автора в неизменном виде, сохранении
						произведения в неизменном виде.
					</p>
					<p>
						7.4. Любое использование Сервиса или Контента, кроме разрешенного в настоящем Соглашении или в
						случае явно выраженного согласия Правообладателя на такое использование, без предварительного
						письменного разрешения Правообладателя, категорически запрещено.
					</p>
					<h2>7. ИСКЛЮЧИТЕЛЬНЫЕ ПРАВА НА КОНТЕНТ</h2>
					<p>
						7.1. Все объекты, размещенные на Сервисе, в том числе элементы дизайна, текст, графические
						изображения, иллюстрации, видео, скрипты, программы, музыка, звуки и другие объекты и их
						подборки (далее — Контент), являются объектами авторских прав Правообладателя, все права на
						эти объекты защищены.
					</p>
					<p>
						7.2. Кроме случаев, установленных настоящим Соглашением, а также действующим законодательством
						Российской Федерации, Контент не может быть скопирован (воспроизведен), переработан,
						распространен, опубликован, скачан, передан, продан или иным способом использован целиком или
						по частям без предварительного разрешения Правообладателя, кроме случаев, когда
						Правообладатель явным образом выразил свое согласие на свободное использование Контента любым
						лицом.
					</p>
					<p>
						7.3. Использование Пользователем Контента, доступ к которому получен исключительно для личного
						некоммерческого использования, допускается при условии сохранения всех знаков авторства или
						других уведомлений об авторстве, сохранения имени автора в неизменном виде, сохранении
						произведения в неизменном виде.
					</p>
					<h2>8. ПРИМЕНИМОЕ ЗАКОНОДАТЕЛЬСТВО И ПОРЯДОК РАЗРЕШЕНИЯ СПОРОВ</h2>
					<p>
						8.1. Настоящее Соглашение регулируется и толкуется в соответствии с законодательством
						Российской Федерации.
					</p>
					<p>
						8.2. Все споры, возникающие в связи с использованием Сервиса, разрешаются Пользователем и
						Правообладателем в досудебном порядке путем направления претензии. Срок рассмотрение претензии
						35 (тридцать пять) рабочих дней. При невозможности прийти к согласию в досудебном порядке
						споры между Пользователем и Правообладателем подлежат рассмотрению в суде г. Челябинск.
					</p>
					<h2>9. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h2>
					<p>
						9.1. Администрация оставляет за собой право в любое время изменить настоящее Соглашение без
						предварительного уведомления. Актуальная версия Соглашения доступна на Сервисе и вступает в
						силу с момента опубликования.
					</p>
					<p>
						9.2. Если какое-либо условие настоящего Соглашения будет признано судом или любым другим
						компетентным органом недействительным, незаконным или не имеющим силу, то это не повлияет на
						действительность, законность и действие остальных условий настоящего Соглашения.
					</p>
					<p>
						9.3. Настоящее Соглашение представляет собой договор относительно порядка использования
						сервиса и заменяет собой все предыдущие соглашения между Пользователем и Правообладателем либо
						Администрацией.
					</p>
					<p>
						9.4 Бездействие со стороны Правообладателя в случае нарушения Пользователем положений
						Соглашений не лишает Правообладателя права предпринять соответствующие действия в защиту своих
						интересов позднее, а также не означает отказ Правообладателя от своих прав в случае совершения
						в последующем подобных либо аналогичных нарушений.
					</p>
					<p>
						9.5. Настоящее Соглашение составлено на русском языке и в некоторых случаях может быть
						предоставлено Пользователю для ознакомления на другом языке. В случае расхождения
						русскоязычной версии Соглашения и версии Соглашения на ином языке, применяются положения
						русскоязычной версии настоящего Соглашения.
					</p>
				</div>
			</main>
			<footer>
				<Footer />
			</footer>
		</div>
	)
}

export default Agreement
