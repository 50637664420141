import style from './contact.module.scss'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'

const Contact = () => {
	const notify = () => {
		toast.success('Ваша заявка отправлена!', {
			position: 'bottom-center',
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: 'light'
		})
	}

	const notifyError = () => {
		toast.error('Что то не так', {
			position: 'bottom-center',
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: 'light'
		})
	}

	const notifyWarn = () => {
		toast.warn('Сетевая ошибка...', {
			position: 'bottom-center',
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: 'light'
		})
	}

	const [form, setForm] = useState({
		contact: '',
		name: '',
		question: '',
		consent: false
	})

	const [errors, setErrors] = useState({
		contact: '',
		question: ''
	})

	const handleChange = e => {
		const { name, value, type, checked } = e.target
		setForm(prevState => ({
			...prevState,
			[name]: type === 'checkbox' ? checked : value
		}))

		// Обновление ошибок при изменении поля
		if (name === 'contact') {
			setErrors(prevState => ({
				...prevState,
				contact: value.trim() === '' ? 'Поле обязательно для заполнения' : ''
			}))
		} else if (name === 'question') {
			setErrors(prevState => ({
				...prevState,
				question: value.length > 200 ? 'Вопрос не должен превышать 200 символов' : ''
			}))
		}
	}

	const handleSubmit = e => {
		e.preventDefault()

		const newErrors = {
			contact: form.contact.trim() === '' ? 'Поле обязательно для заполнения' : '',
			question:
				form.question.trim() === ''
					? 'Поле обязательно для заполнения'
					: form.question.length > 200
					? 'Вопрос не должен превышать 200 символов'
					: ''
		}

		setErrors(newErrors)

		const hasErrors = Object.values(newErrors).some(error => error !== '')

		if (hasErrors) {
			return
		}

		const formData = {
			contact: form.contact,
			name: form.name.trim() === '' ? '' : form.name,
			question: form.question
		}

		axios.post('https://zashita774.ru/api/contact-form/', formData, {
			headers: {
				Accept: 'application/x-www-form-urlencoded',
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		})
			.then(response => {
				if (response.status === 200) {
					notify()
					setForm({
						contact: '',
						name: '',
						question: '',
						consent: false
					})
					setErrors({
						contact: '',
						question: ''
					})
				} else {
					notifyError()
				}
			})
			.catch(error => {
				notifyWarn()
			})
	}

	return (
		<div className={style.contact} id='contact'>
			<div className={style.block_right}>
				<h1>Связаться с нами:</h1>

				<div className={style.contacts1}>
					<a href='https://t.me/Zashita774'>
						<svg
							width='32'
							height='32'
							viewBox='0 0 32 32'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M32 16.001C32 24.8375 24.8366 32.001 16 32.001C7.16344 32.001 0 24.8375 0 16.001C0 7.16442 7.16344 0.000976562 16 0.000976562C24.8366 0.000976562 32 7.16442 32 16.001ZM16.5734 11.8129C15.0171 12.4602 11.9068 13.7999 7.24252 15.8321C6.48511 16.1333 6.08834 16.4279 6.05222 16.716C5.99118 17.203 6.60095 17.3947 7.43129 17.6558C7.54424 17.6913 7.66127 17.7281 7.78125 17.7671C8.59818 18.0327 9.6971 18.3433 10.2684 18.3557C10.7866 18.3669 11.365 18.1532 12.0035 17.7148C16.3615 14.773 18.6112 13.2861 18.7524 13.254C18.8521 13.2314 18.9901 13.203 19.0837 13.2861C19.1772 13.3693 19.168 13.5267 19.1581 13.569C19.0977 13.8265 16.7042 16.0518 15.4655 17.2033C15.0793 17.5623 14.8054 17.817 14.7494 17.8752C14.624 18.0054 14.4962 18.1287 14.3733 18.2471C13.6144 18.9787 13.0452 19.5274 14.4048 20.4234C15.0582 20.8539 15.581 21.21 16.1026 21.5652C16.6722 21.9531 17.2404 22.34 17.9755 22.8219C18.1628 22.9446 18.3416 23.0721 18.5159 23.1963C19.1788 23.6689 19.7743 24.0935 20.5101 24.0258C20.9377 23.9865 21.3793 23.5845 21.6036 22.3854C22.1336 19.5518 23.1755 13.4123 23.4163 10.8823C23.4374 10.6606 23.4108 10.3769 23.3895 10.2524C23.3682 10.1279 23.3237 9.95045 23.1618 9.81911C22.9701 9.66356 22.6742 9.63076 22.5418 9.63309C21.94 9.64369 21.0167 9.96473 16.5734 11.8129Z'
								fill='#101828'
							/>
						</svg>
					</a>
					<a href='https://wa.me/+79220128404'>
						<svg
							width='32'
							height='32'
							viewBox='0 0 32 32'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<g clipPath='url(#clip0_49_3828)'>
								<path
									d='M0 32.001L2.18944 23.7836C0.838399 21.3783 0.128485 18.6516 0.129783 15.8556C0.133677 7.11431 7.05761 0.000976562 15.5649 0.000976562C19.6933 0.0023099 23.5686 1.65431 26.4835 4.65164C29.3972 7.64898 31.0013 11.633 31 15.8703C30.9961 24.613 24.0722 31.7263 15.5649 31.7263C12.9822 31.725 10.4372 31.0596 8.18283 29.7956L0 32.001ZM8.56179 26.925C10.737 28.2516 12.8135 29.0463 15.5597 29.0476C22.6303 29.0476 28.3901 23.1356 28.394 15.8676C28.3965 8.58498 22.664 2.68098 15.5701 2.67831C8.49431 2.67831 2.73842 8.59031 2.73583 15.857C2.73453 18.8236 3.58072 21.045 5.00184 23.369L3.70531 28.233L8.56179 26.925ZM23.3402 19.6396C23.2442 19.4743 22.9872 19.3756 22.6004 19.177C22.215 18.9783 20.3188 18.0196 19.9645 17.8876C19.6115 17.7556 19.3546 17.689 19.0963 18.0863C18.8393 18.4823 18.0996 19.3756 17.875 19.6396C17.6505 19.9036 17.4247 19.937 17.0392 19.7383C16.6538 19.5396 15.4104 19.1223 13.9374 17.7716C12.7914 16.721 12.0166 15.4236 11.7921 15.0263C11.5676 14.6303 11.7687 14.4156 11.9608 14.2183C12.1347 14.041 12.3463 13.7556 12.5396 13.5236C12.7356 13.2943 12.7992 13.129 12.929 12.8636C13.0575 12.5996 12.9939 12.3676 12.8966 12.169C12.7992 11.9716 12.0283 10.021 11.7077 9.22764C11.3937 8.45564 11.0757 8.55964 10.8395 8.54764L10.0997 8.53431C9.84275 8.53431 9.42485 8.63298 9.07184 9.03031C8.71883 9.42764 7.7221 10.385 7.7221 12.3356C7.7221 14.2863 9.10429 16.1703 9.29636 16.4343C9.48974 16.6983 12.0153 20.701 15.8842 22.417C16.8043 22.825 17.5233 23.069 18.0827 23.2516C19.0067 23.553 19.8477 23.5103 20.5122 23.409C21.2533 23.2956 22.7938 22.4503 23.1157 21.525C23.4375 20.5983 23.4375 19.805 23.3402 19.6396Z'
									fill='#101828'
								/>
							</g>
							<defs>
								<clipPath id='clip0_49_3828'>
									<rect
										width='32'
										height='32'
										fill='white'
										transform='translate(0 0.000976562)'
									/>
								</clipPath>
							</defs>
						</svg>
					</a>
				</div>

				<div className={style.contacts2}>
					<a href='contact:+89220128404'>
						<span>
							<svg
								width='22'
								height='23'
								viewBox='0 0 22 23'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M21 16.4211V19.4211C21.0011 19.6996 20.9441 19.9752 20.8325 20.2304C20.7209 20.4856 20.5573 20.7147 20.3521 20.9029C20.1468 21.0912 19.9046 21.2346 19.6407 21.3238C19.3769 21.413 19.0974 21.4461 18.82 21.4211C15.7428 21.0867 12.787 20.0352 10.19 18.3511C7.77382 16.8157 5.72533 14.7672 4.18999 12.3511C2.49997 9.74228 1.44824 6.77207 1.11999 3.68107C1.095 3.40454 1.12787 3.12583 1.21649 2.8627C1.30512 2.59956 1.44756 2.35776 1.63476 2.15269C1.82196 1.94763 2.0498 1.78378 2.30379 1.6716C2.55777 1.55941 2.83233 1.50133 3.10999 1.50107H6.10999C6.5953 1.4963 7.06579 1.66815 7.43376 1.98461C7.80173 2.30106 8.04207 2.74052 8.10999 3.22107C8.23662 4.18114 8.47144 5.1238 8.80999 6.03107C8.94454 6.389 8.97366 6.77799 8.8939 7.15195C8.81415 7.52592 8.62886 7.86918 8.35999 8.14107L7.08999 9.41107C8.51355 11.9146 10.5864 13.9875 13.09 15.4111L14.36 14.1411C14.6319 13.8722 14.9751 13.6869 15.3491 13.6072C15.7231 13.5274 16.1121 13.5565 16.47 13.6911C17.3773 14.0296 18.3199 14.2645 19.28 14.3911C19.7658 14.4596 20.2094 14.7043 20.5265 15.0786C20.8437 15.4529 21.0122 15.9306 21 16.4211Z'
									stroke='#101828'
									strokeWidth='2'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
							</svg>
							+8 (922) 012-84-04
						</span>
					</a>
					<a>
						<span>
							ИНН 745109719252
							<br /> Воронина Наталья Владимировна
						</span>
					</a>
					<a href='mailto:zashita774@yandex.ru'>
						<span>
							<svg
								width='24'
								height='25'
								viewBox='0 0 24 25'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M22 6.50098C22 5.40098 21.1 4.50098 20 4.50098H4C2.9 4.50098 2 5.40098 2 6.50098M22 6.50098V18.501C22 19.601 21.1 20.501 20 20.501H4C2.9 20.501 2 19.601 2 18.501V6.50098M22 6.50098L12 13.501L2 6.50098'
									stroke='#101828'
									strokeWidth='2'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
							</svg>
							zashita774@yandex.ru
						</span>
					</a>
					<span>
						<svg
							width='20'
							height='25'
							viewBox='0 0 20 25'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M19 10.501C19 17.501 10 23.501 10 23.501C10 23.501 1 17.501 1 10.501C1 8.11403 1.94821 5.82484 3.63604 4.13702C5.32387 2.44919 7.61305 1.50098 10 1.50098C12.3869 1.50098 14.6761 2.44919 16.364 4.13702C18.0518 5.82484 19 8.11403 19 10.501Z'
								stroke='#101828'
								strokeWidth='2'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
							<path
								d='M10 13.501C11.6569 13.501 13 12.1578 13 10.501C13 8.84412 11.6569 7.50098 10 7.50098C8.34315 7.50098 7 8.84412 7 10.501C7 12.1578 8.34315 13.501 10 13.501Z'
								stroke='#101828'
								strokeWidth='2'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
						</svg>
						г. Челябинск
					</span>
				</div>
			</div>
			<div className={style.block_left}>
				<form onSubmit={handleSubmit}>
					<input name='name' placeholder='Ваше Имя' type='text' value={form.name} onChange={handleChange} />
					<input
						className={errors.contact ? style.input_error : ''}
						name='contact'
						placeholder='Телефон или почта*'
						type='text'
						value={form.contact}
						onChange={handleChange}
					/>

					<input
						name='question'
						type='text'
						className={errors.question ? style.input_error : ''}
						placeholder='Ваш вопрос'
						value={form.question}
						onChange={handleChange}
						rows={4}
					/>

					<div className={style.checkbox}>
						<input
							id='c1'
							name='consent'
							type='checkbox'
							checked={form.consent}
							onChange={handleChange}
							required
						/>
						<Link to='/politics'>
							<span>
								Я даю своё согласие на обработку персональных данных и соглашаюсь с условиями
								Политики конфиденциальности
							</span>
						</Link>
					</div>
					<button type='submit'>Отправить</button>
				</form>
			</div>
		</div>
	)
}

export default Contact
