import About from '../Components/About/About'
import BlockCheckPerson from '../Components/BlockCheckPerson/BlockCheckPerson'
import Contact from '../Components/ContactUs/Contact'
import Footer from '../Components/Footer/Footer'
import Header from '../Components/Header/Header'
import Main from '../Components/Main/Main'
import Purchase from '../Components/Purchase/Pusrchase'
import Services from '../Components/Services/Services'

const MainPage = () => {
	return (
		<div>
			<header>
				<Header />
			</header>
			<main>
				<Main />
				<BlockCheckPerson />
				<About />
				<Services />
				<Purchase />
				<Contact />
			</main>
			<footer>
				<Footer />
			</footer>
		</div>
	)
}

export default MainPage
