import style from './about.module.scss'

import document from '../../images/document.svg'
import shield from '../../images/shield.svg'

const About = () => {
	return (
		<div className={style.about}>
			<div className={style.block_left}>
				<img src={document} alt='document'></img>
				<h1>Наша миссия</h1>
				<p>
					Защитить партнёров от субъективных
					<br /> факторов потери бизнеса.
				</p>
			</div>
			<div className={style.block_left}>
				<img src={shield} alt='shield'></img>
				<h1>Наша цель</h1>
				<p>
					Минимизация рисков партнёров, связанных с неблагонадёжным персоналом
					<br /> и контрагентами, закрытие каналов утечки информации конкурентам.
				</p>
			</div>
		</div>
	)
}

export default About
