import './App.css'
import MainPage from './Pages/MainPage'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Politics from './Pages/Politics'
import Agreement from './Pages/Agreement'
import ScrollToTop from './Scripts/ScrollToTop'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const App = () => {
	return (
		<Router>
			<ScrollToTop />
			<Routes>
				<Route path='/' element={<MainPage />} />
				<Route path='/politics' element={<Politics />} />
				<Route path='/agreement' element={<Agreement />} />
			</Routes>
			<ToastContainer />
		</Router>
	)
}

export default App
